<template>
  <div class="proposal-form">
    <div v-if="isEdit !== null" class="form-header">
      <div class="form-header-top">
        <div class="title">
          <span>Предзаявка №{{ getPreApplicationInfo.orderId }}</span>
        </div>
        <div class="status">
          <span>{{ getPreApplicationInfo.status }}</span>
          <span 
            v-if="applicationId"
            class="status__app-id"
            @click="$router.push(`/application/${applicationId}`)"
          >
            {{ `№${applicationId}` }}
          </span>
          <span v-if="preappPaymentStatus && !isIdentification">{{", оплачена"}}</span>
        </div>
      </div>
      <div class="form-header-bottom">
        <ul class="date">
          <li v-if="creationDate">
            <span>Создана</span>
            {{ getPreApplicationInfo.creationDate }}
          </li>
        </ul>
        <ul class="applicant">
          <li>
            <span>Заявитель:</span>
            {{ getPreApplicationInfo.typeEntre }}
          </li>
          <li v-if="getFnsExistingCerts.length">
            <FnsExistingCertList :certList="getFnsExistingCerts" />
          </li>
        </ul>
      </div>
      <div v-if="getAdditionalStatusVisible">
        <div  title="Изменить статус">
          <div class="form-box-group">
            <Select
              label="Статус"
              name="statusInputId"
              :valid="additionalStatusId !== null"
              :options="optionsAdditionalStatuses"
              :value="additionalStatusId"
              invalidityInfo="Поле не должно быть пустым"
              @change="(evt) => this.additionalStatusId = evt.target.value"
              @blur="(evt) => this.additionalStatusId = evt.target.value"
            />
            <TextArea
              name="filialComment"
              label="Комментарий"
              :valid="!!commentText"
              :value="commentText"
              invalidityInfo="Поле не должно быть пустым"
              @change="onChangeComment"
              @blur="onChangeComment"
            />
            <Button
              :disabled="!commentText || additionalStatusId === null"
              @click="handleClickSaveStatus"
            >
              <span>Сохранить</span>
            </Button>
            <Button
              style="margin-left: 30px"
              type="button"
              @click="handleCancelSaveStatus"
            >
              <span>Отмена</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
    <Form>
      <InputRadio
        v-if="isEdit === null"
        class="type-entre"
        name="typeEntre"
        @change="typeSelectionHandler"
        :value="formControls.typeEntre.value"
        :disabled="formControls.typeEntre.disabled"
        :val="[
          { label: 'Физическое лицо', value: 'fl' },
          { label: 'Индивидуальный предприниматель', value: 'ip' },
          { label: 'Юридическое лицо', value: 'ur' }
        ]"
      />


      <FormBox v-if="getVisibleBodyForm" title="Заявитель">
        <div class="form-box-group">
          <Input
            v-if="formControls.surname.active"
            name="surname"
            label="Фамилия"
            :valid="formControls.surname.isValid"
            :errors="formControls.surname.errors"
            :touched="formControls.surname.isTouched"
            :shouldValidate="!!formControls.surname.rules"
            :value="formControls.surname.value"
            :disabled="formControls.surname.disabled"
            :invalidityInfo="formControls.surname.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.name.active"
            name="name"
            label="Имя"
            :valid="formControls.name.isValid"
            :errors="formControls.name.errors"
            :touched="formControls.name.isTouched"
            :shouldValidate="!!formControls.name.rules"
            :value="formControls.name.value"
            :disabled="formControls.name.disabled"
            :invalidityInfo="formControls.name.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.patronymic.active"
            name="patronymic"
            label="Отчество"
            :valid="formControls.patronymic.isValid"
            :errors="formControls.patronymic.errors"
            :touched="formControls.patronymic.isTouched"
            :shouldValidate="!!formControls.patronymic.rules"
            :value="formControls.patronymic.value"
            :disabled="formControls.patronymic.disabled"
            :invalidityInfo="formControls.patronymic.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <InputRadio
            v-if="formControls.gender.active"
            name="gender"
            label="Пол"
            :valid="formControls.gender.isValid"
            :touched="formControls.gender.isTouched"
            :shouldValidate="!!formControls.gender.rules"
            :value="formControls.gender.value"
            :disabled="formControls.gender.disabled"
            :val="[
              { label: 'Мужской', value: 'M' },
              { label: 'Женский', value: 'F' },
              { label: 'Не указан', value: 'U' }
            ]"
            @change="inputHandler"
            @blur="inputHandler"

          />
          <Input
            v-if="formControls.birthDate.active"
            name="birthDate"
            stype="min"
            label="Дата рождения"
            type="date"
            placeholder="ДД.ММ.ГГГГ"
            :valid="formControls.birthDate.isValid"
            :errors="formControls.birthDate.errors"
            :touched="formControls.birthDate.isTouched"
            :shouldValidate="!!formControls.birthDate.rules"
            :value="formControls.birthDate.value"
            :disabled="formControls.birthDate.disabled"
            :invalidityInfo="formControls.birthDate.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>

        <div
          class="form-box-group"
          v-if="formControls.typeEntre.value === 'ur'"
        >
          <Input
            v-if="formControls.position.active"
            name="position"
            label="Должность"
            :valid="formControls.position.isValid"
            :errors="formControls.position.errors"
            :touched="formControls.position.isTouched"
            :shouldValidate="!!formControls.position.rules"
            :value="formControls.position.value"
            :disabled="formControls.position.disabled"
            :invalidityInfo="formControls.position.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.department.active"
            name="department"
            label="Подразделение/отдел"
            placeholder="Необязательно"
            :valid="formControls.department.isValid"
            :errors="formControls.department.errors"
            :touched="formControls.department.isTouched"
            :shouldValidate="!!formControls.department.rules"
            :value="formControls.department.value"
            :disabled="formControls.department.disabled"
            :invalidityInfo="formControls.department.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>

        <div class="form-box-group">
          <Input
            v-if="formControls.email.active"
            name="email"
            label="Электронная почта"
            :valid="formControls.email.isValid"
            :errors="formControls.email.errors"
            :touched="formControls.email.isTouched"
            :shouldValidate="!!formControls.email.rules"
            :value="formControls.email.value"
            :disabled="formControls.email.disabled"
            :invalidityInfo="formControls.email.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.phone.active"
            name="phone"
            stype="min"
            label="Телефон"
            mask="+7 (999) 999 99-99"
            :valid="formControls.phone.isValid"
            :errors="formControls.phone.errors"
            :touched="formControls.phone.isTouched"
            :shouldValidate="!!formControls.phone.rules"
            :value="formControls.phone.value"
            :disabled="formControls.phone.disabled"
            :invalidityInfo="formControls.phone.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
      </FormBox>

      <FormBox v-if="getVisibleBodyForm" title="Документы">
        <div class="form-box-group">
          <Input
            v-if="formControls.serialDoc.active"
            name="serialDoc"
            stype="min"
            label="Паспорт"
            mask="9999 999999"
            :valid="formControls.serialDoc.isValid"
            :errors="formControls.serialDoc.errors"
            :touched="formControls.serialDoc.isTouched"
            :shouldValidate="!!formControls.serialDoc.rules"
            :value="formControls.serialDoc.value"
            :disabled="formControls.serialDoc.disabled"
            :invalidityInfo="formControls.serialDoc.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.dateDoc.active"
            name="dateDoc"
            stype="min"
            label="Дата выдачи"
            type="date"
            placeholder="ДД.ММ.ГГГГ"
            :valid="formControls.dateDoc.isValid"
            :errors="formControls.dateDoc.errors"
            :touched="formControls.dateDoc.isTouched"
            :shouldValidate="!!formControls.dateDoc.rules"
            :value="formControls.dateDoc.value"
            :disabled="formControls.dateDoc.disabled"
            :invalidityInfo="formControls.dateDoc.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.passportDivisionCode.active"
            name="passportDivisionCode"
            stype="min"
            label="Код подразделения"
            mask="999 999"
            :valid="formControls.passportDivisionCode.isValid"
            :errors="formControls.passportDivisionCode.errors"
            :touched="formControls.passportDivisionCode.isTouched"
            :shouldValidate="!!formControls.passportDivisionCode.rules"
            :value="formControls.passportDivisionCode.value"
            :disabled="formControls.passportDivisionCode.disabled"
            :invalidityInfo="formControls.passportDivisionCode.invalidityInfo"
            :inputControls="!isEdit ? {} : {
              buttonType: 'normal',
              buttonTitle: 'Заполнить &quot;Кем выдан&quot;',
              buttonHandler: getDivisionNameByCode,
              buttonStyles: {width: '130px', padding: '0'},
              buttonDisabled: !formControls.payerInn.isValid,
            }"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Select
            v-if="formControls.passportDivision.active && optionsPassportDivisions.length > 0"
            name="passportDivision"
            label="Кем выдан"
            :valid="formControls.passportDivision.isValid"
            :touched="formControls.passportDivision.isTouched"
            :shouldValidate="!!formControls.passportDivision.rules"
            :value="formControls.passportDivision.value"
            :disabled="formControls.passportDivision.disabled"
            :options="optionsPassportDivisions"
            @change="(evt) => (evt.target.value === null 
              ? switchDivisionSelectToTextArea() 
              : inputHandler(evt))"
            @blur="inputHandler"
          />
          <TextArea
            v-if="formControls.passportDivision.active && optionsPassportDivisions.length === 0"
            name="passportDivision"
            label="Кем выдан"
            :valid="formControls.passportDivision.isValid"
            :errors="formControls.passportDivision.errors"
            :touched="formControls.passportDivision.isTouched"
            :shouldValidate="!!formControls.passportDivision.rules"
            :value="formControls.passportDivision.value"
            :disabled="formControls.passportDivision.disabled"
            :invalidityInfo="formControls.passportDivision.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>

        <div class="form-box-group">
          <Input
            v-if="formControls.snils.active"
            name="snils"
            stype="min"
            label="СНИЛС"
            mask="999-999-999 99"
            :valid="formControls.snils.isValid"
            :errors="formControls.snils.errors"
            :touched="formControls.snils.isTouched"
            :shouldValidate="!!formControls.snils.rules"
            :value="formControls.snils.value"
            :disabled="formControls.snils.disabled"
            :invalidityInfo="formControls.snils.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.typeEntre.value !== 'ur' && formControls.inn.active"
            name="inn"
            stype="min"
            label="ИНН"
            mask="999999999999"
            :valid="formControls.inn.isValid"
            :errors="formControls.inn.errors"
            :touched="formControls.inn.isTouched"
            :shouldValidate="!!formControls.inn.rules"
            :value="formControls.inn.value"
            :disabled="formControls.inn.disabled"
            :invalidityInfo="formControls.inn.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.personInn.active"
            name="personInn"
            stype="min"
            label="ИНН заявителя"
            mask="999999999999"
            :valid="formControls.personInn.isValid"
            :errors="formControls.personInn.errors"
            :touched="formControls.personInn.isTouched"
            :shouldValidate="!!formControls.personInn.rules"
            :value="formControls.personInn.value"
            :disabled="formControls.personInn.disabled"
            :invalidityInfo="formControls.personInn.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.ogrnip.active"
            name="ogrnip"
            stype="min"
            label="ОГРНИП"
            mask="999999999999999"
            :valid="formControls.ogrnip.isValid"
            :errors="formControls.ogrnip.errors"
            :touched="formControls.ogrnip.isTouched"
            :shouldValidate="!!formControls.ogrnip.rules"
            :value="formControls.ogrnip.value"
            :disabled="formControls.ogrnip.disabled"
            :invalidityInfo="formControls.ogrnip.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.ogrn.active"
            name="ogrn"
            stype="min"
            label="ОГРН"
            mask="9999999999999"
            :valid="formControls.ogrn.isValid"
            :errors="formControls.ogrn.errors"
            :touched="formControls.ogrn.isTouched"
            :shouldValidate="!!formControls.ogrn.rules"
            :value="formControls.ogrn.value"
            :disabled="formControls.ogrn.disabled"
            :invalidityInfo="formControls.ogrn.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
      </FormBox>

      <FormBox
        v-if="
          (getVisibleBodyForm && formControls.typeEntre.value === 'ur') ||
            (getVisibleBodyForm && formControls.typeEntre.value === 'ip')
        "
        title="Организация"
      >
        <div class="form-box-group">
          <TextArea
            v-if="formControls.fullNameOrg.active"
            name="fullNameOrg"
            label="Полное наименование организации"
            :valid="formControls.fullNameOrg.isValid"
            :errors="formControls.fullNameOrg.errors"
            :touched="formControls.fullNameOrg.isTouched"
            :shouldValidate="!!formControls.fullNameOrg.rules"
            :value="formControls.fullNameOrg.value"
            :disabled="formControls.fullNameOrg.disabled"
            :invalidityInfo="formControls.fullNameOrg.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.shortNameOrg.active"
            name="shortNameOrg"
            stype="min"
            label="Сокращенное наименование"
            :valid="formControls.shortNameOrg.isValid"
            :errors="formControls.shortNameOrg.errors"
            :touched="formControls.shortNameOrg.isTouched"
            :shouldValidate="!!formControls.shortNameOrg.rules"
            :value="formControls.shortNameOrg.value"
            :disabled="formControls.shortNameOrg.disabled"
            :invalidityInfo="formControls.shortNameOrg.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
        <div class="form-box-group">
          <Input
            v-if="formControls.typeEntre.value === 'ur' && formControls.inn.active"
            name="inn"
            stype="min"
            label="ИНН организации"
            mask="9999999999"
            :valid="formControls.inn.isValid"
            :errors="formControls.inn.errors"
            :touched="formControls.inn.isTouched"
            :shouldValidate="!!formControls.inn.rules"
            :value="formControls.inn.value"
            :disabled="formControls.inn.disabled"
            :invalidityInfo="formControls.inn.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.kpp.active"
            name="kpp"
            stype="min"
            label="КПП"
            mask="${9}"
            :valid="formControls.kpp.isValid"
            :errors="formControls.kpp.errors"
            :touched="formControls.kpp.isTouched"
            :shouldValidate="!!formControls.kpp.rules"
            :value="formControls.kpp.value"
            :disabled="formControls.kpp.disabled"
            :invalidityInfo="formControls.kpp.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.phoneOrg.active"
            name="phoneOrg"
            stype="min"
            label="Телефон организации"
            mask="+7 (999) 999 99-99"
            :valid="formControls.phoneOrg.isValid"
            :errors="formControls.phoneOrg.errors"
            :touched="formControls.phoneOrg.isTouched"
            :shouldValidate="!!formControls.phoneOrg.rules"
            :value="formControls.phoneOrg.value"
            :disabled="formControls.phoneOrg.disabled"
            :invalidityInfo="formControls.phoneOrg.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
      </FormBox>

      <FormBox v-if="getVisibleBodyForm" title="Гражданство">
        <div class="form-box-group">
          <Select
            v-if="formControls.countryId.active"
            label="Страна"
            name="countryId"
            :valid="formControls.countryId.isValid"
            :errors="formControls.countryId.errors"
            :touched="formControls.countryId.isTouched"
            :shouldValidate="!!formControls.countryId.rules"
            :disabled="formControls.countryId.disabled"
            :options="optionsCountry"
            :value="formControls.countryId.value"
            :invalidityInfo="formControls.countryId.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
      </FormBox>

      <FormBox v-if="getVisibleBodyForm" :title="getAddressTitle">
        <div class="form-box-group">
          <Select
            v-if="formControls.regionRealId.active"
            label="Регион"
            name="regionRealId"
            :valid="formControls.regionRealId.isValid"
            :errors="formControls.regionRealId.errors"
            :touched="formControls.regionRealId.isTouched"
            :shouldValidate="!!formControls.regionRealId.rules"
            :disabled="formControls.regionRealId.disabled"
            :options="optionsRegion"
            :value="formControls.regionRealId.value"
            :invalidityInfo="formControls.regionRealId.invalidityInfo"
            :hasSearch="true"
            @change="inputHandler"
            @blur="inputHandler"
          />

          <Select
              v-if="formControls.regionLawId.active"
              label="Регион"
              name="regionLawId"
              :disabled="formControls.regionLawId.disabled"
              :valid="formControls.regionLawId.isValid"
              :errors="formControls.regionLawId.errors"
              :touched="formControls.regionLawId.isTouched"
              :shouldValidate="!!formControls.regionLawId.rules"
              :options="optionsRegion"
              :value="formControls.regionLawId.value"
              :invalidityInfo="formControls.regionLawId.invalidityInfo"
              :hasSearch="true"
              @change="inputHandler"
              @blur="inputHandler"
          />

          <!-- раздельный адрес -->
          <div v-if="getHasTariffInsurance">
            <ComboWrapper title="Населенный пункт">
              <Select
                style="width: 50%"
                name="separatedAddressLocalityType"
                placeholder="Выберите тип"
                :disabled="formControls.separatedAddressLocalityType.disabled"
                :valid="formControls.separatedAddressLocalityType.isValid"
                :errors="formControls.separatedAddressLocalityType.errors"
                :touched="formControls.separatedAddressLocalityType.isTouched"
                :shouldValidate="!!formControls.separatedAddressLocalityType.rules"
                :options="LOCALITY_TYPES"
                :value="formControls.separatedAddressLocalityType.value"
                :invalidityInfo="formControls.separatedAddressLocalityType.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
              <Input
                style="width: 50%"
                stype="max"
                name="separatedAddressLocalityValue"
                placeholder="Наименование"
                :valid="formControls.separatedAddressLocalityValue.isValid"
                :errors="formControls.separatedAddressLocalityValue.errors"
                :touched="formControls.separatedAddressLocalityValue.isTouched"
                :shouldValidate="!!formControls.separatedAddressLocalityValue.rules"
                :value="formControls.separatedAddressLocalityValue.value"
                :disabled="formControls.separatedAddressLocalityValue.disabled"
                :invalidityInfo="formControls.separatedAddressLocalityValue.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
            </ComboWrapper>
            <ComboWrapper title="Улица">
              <Select
                style="width: 50%"
                name="separatedAddressRoadType"
                placeholder="Выберите тип"
                :disabled="formControls.separatedAddressRoadType.disabled"
                :valid="formControls.separatedAddressRoadType.isValid"
                :errors="formControls.separatedAddressRoadType.errors"
                :touched="formControls.separatedAddressRoadType.isTouched"
                :shouldValidate="!!formControls.separatedAddressRoadType.rules"
                :options="ROAD_TYPES"
                :value="formControls.separatedAddressRoadType.value"
                :invalidityInfo="formControls.separatedAddressRoadType.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
              <Input
                style="width: 50%"
                stype="max"
                name="separatedAddressRoadValue"
                placeholder="Наименование"
                :valid="formControls.separatedAddressRoadValue.isValid"
                :errors="formControls.separatedAddressRoadValue.errors"
                :touched="formControls.separatedAddressRoadValue.isTouched"
                :shouldValidate="!!formControls.separatedAddressRoadValue.rules"
                :value="formControls.separatedAddressRoadValue.value"
                :disabled="formControls.separatedAddressRoadValue.disabled"
                :invalidityInfo="formControls.separatedAddressRoadValue.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
            </ComboWrapper>
            <ComboWrapper title="Строение">
              <Select
                style="width: 50%"
                name="separatedAddressBuildingType"
                placeholder="Выберите тип"
                :disabled="formControls.separatedAddressBuildingType.disabled"
                :valid="formControls.separatedAddressBuildingType.isValid"
                :errors="formControls.separatedAddressBuildingType.errors"
                :touched="formControls.separatedAddressBuildingType.isTouched"
                :shouldValidate="!!formControls.separatedAddressBuildingType.rules"
                :options="BUILDING_TYPES"
                :value="formControls.separatedAddressBuildingType.value"
                :invalidityInfo="formControls.separatedAddressBuildingType.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
              <Input
                style="width: 50%"
                stype="max"
                name="separatedAddressBuildingValue"
                placeholder="Наименование"
                :valid="formControls.separatedAddressBuildingValue.isValid"
                :errors="formControls.separatedAddressBuildingValue.errors"
                :touched="formControls.separatedAddressBuildingValue.isTouched"
                :shouldValidate="!!formControls.separatedAddressBuildingValue.rules"
                :value="formControls.separatedAddressBuildingValue.value"
                :disabled="formControls.separatedAddressBuildingValue.disabled"
                :invalidityInfo="formControls.separatedAddressBuildingValue.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
            </ComboWrapper>
            <ComboWrapper title="Помещение">
              <Select
                style="width: 50%"
                name="separatedAddressApartmentType"
                placeholder="Выберите тип"
                :disabled="formControls.separatedAddressApartmentType.disabled"
                :valid="formControls.separatedAddressApartmentType.isValid"
                :errors="formControls.separatedAddressApartmentType.errors"
                :touched="formControls.separatedAddressApartmentType.isTouched"
                :shouldValidate="!!formControls.separatedAddressApartmentType.rules"
                :options="APARTMENT_TYPES"
                :value="formControls.separatedAddressApartmentType.value"
                :invalidityInfo="formControls.separatedAddressApartmentType.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
              <Input
                style="width: 50%"
                stype="max"
                name="separatedAddressApartmentValue"
                placeholder="Наименование"
                :valid="formControls.separatedAddressApartmentValue.isValid"
                :errors="formControls.separatedAddressApartmentValue.errors"
                :touched="formControls.separatedAddressApartmentValue.isTouched"
                :shouldValidate="!!formControls.separatedAddressApartmentValue.rules"
                :value="formControls.separatedAddressApartmentValue.value"
                :disabled="formControls.separatedAddressApartmentValue.disabled"
                :invalidityInfo="formControls.separatedAddressApartmentValue.invalidityInfo"
                @change="inputHandler"
                @blur="inputHandler"
              />
            </ComboWrapper>
            <Input
              name="addressReadOnly"
              label="Адрес (только для чтения)"
              :value="addressReadOnly"
              :valid="true"
              :touched="true"
              :disabled="true"
              :shouldValidate="false"
            />
          </div>
          <!-- Нераздельный адрес -->
          <div v-if="!getHasTariffInsurance">
            <Input
              v-if="formControls.cityReal.active"
              name="cityReal"
              label="Населенный пункт"
              :valid="formControls.cityReal.isValid"
              :errors="formControls.cityReal.errors"
              :touched="formControls.cityReal.isTouched"
              :shouldValidate="!!formControls.cityReal.rules"
              :value="formControls.cityReal.value"
              :disabled="formControls.cityReal.disabled"
              :invalidityInfo="formControls.cityReal.invalidityInfo"
              @change="inputHandler"
              @blur="inputHandler"
            />
            <Input
              v-if="formControls.addressReal.active"
              name="addressReal"
              placeholder="Необязательно"
              :label="
                formControls.typeEntre.value === 'ip'
                  ? 'Адрес (согласно выписке ЕГРИП) начиная с улицы'
                  : 'Адрес'
              "
              :valid="formControls.addressReal.isValid"
              :errors="formControls.addressReal.errors"
              :touched="formControls.addressReal.isTouched"
              :shouldValidate="!!formControls.addressReal.rules"
              :value="formControls.addressReal.value"
              :disabled="formControls.addressReal.disabled"
              :invalidityInfo="formControls.addressReal.invalidityInfo"
              @change="inputHandler"
              @blur="inputHandler"
            />
            <Input
              v-if="formControls.cityLaw.active"
              name="cityLaw"
              label="Населенный пункт"
              :valid="formControls.cityLaw.isValid"
              :errors="formControls.cityLaw.errors"
              :touched="formControls.cityLaw.isTouched"
              :shouldValidate="!!formControls.cityLaw.rules"
              :value="formControls.cityLaw.value"
              :disabled="formControls.cityLaw.disabled"
              :invalidityInfo="formControls.cityLaw.invalidityInfo"
              @change="inputHandler"
              @blur="inputHandler"
            />
            <Input
              v-if="formControls.addressLaw.active"
              name="addressLaw"
              label="Адрес (согласно выписке ЕГРЮЛ) начиная с улицы"
              :valid="formControls.addressLaw.isValid"
              :errors="formControls.addressLaw.errors"
              :touched="formControls.addressLaw.isTouched"
              :shouldValidate="!!formControls.addressLaw.rules"
              :value="formControls.addressLaw.value"
              :disabled="formControls.addressLaw.disabled"
              :invalidityInfo="formControls.addressLaw.invalidityInfo"
              @change="inputHandler"
              @blur="inputHandler"
            />
          </div>
          <!--  -->

          <Input
            v-if="formControls.index.active"
            name="index"
            stype="min"
            label="Индекс"
            mask="999999"
            :valid="formControls.index.isValid"
            :errors="formControls.index.errors"
            :touched="formControls.index.isTouched"
            :shouldValidate="!!formControls.index.rules"
            :value="formControls.index.value"
            :disabled="formControls.index.disabled"
            :invalidityInfo="formControls.index.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
      </FormBox>

      <!--TO-DO: секцию Руководитель надо будет убрать -->
      <FormBox
        v-if="getVisibleBodyForm && formControls.typeEntre.value === 'ur'"
        title="Руководитель"
      >
        <div class="form-box-group">
          <Input
            v-if="formControls.surnameOrg.active"
            name="surnameOrg"
            label="Фамилия"
            :valid="formControls.surnameOrg.isValid"
            :errors="formControls.surnameOrg.errors"
            :touched="formControls.surnameOrg.isTouched"
            :shouldValidate="!!formControls.surnameOrg.rules"
            :value="formControls.surnameOrg.value"
            :disabled="formControls.surnameOrg.disabled"
            :invalidityInfo="formControls.surnameOrg.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.nameOrg.active"
            name="nameOrg"
            label="Имя"
            :valid="formControls.nameOrg.isValid"
            :errors="formControls.nameOrg.errors"
            :touched="formControls.nameOrg.isTouched"
            :shouldValidate="!!formControls.nameOrg.rules"
            :value="formControls.nameOrg.value"
            :disabled="formControls.nameOrg.disabled"
            :invalidityInfo="formControls.nameOrg.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.patronymicOrg.active"
            name="patronymicOrg"
            label="Отчество"
            :valid="formControls.patronymicOrg.isValid"
            :errors="formControls.patronymicOrg.errors"
            :touched="formControls.patronymicOrg.isTouched"
            :shouldValidate="!!formControls.patronymicOrg.rules"
            :value="formControls.patronymicOrg.value"
            :disabled="formControls.patronymicOrg.disabled"
            :invalidityInfo="formControls.patronymicOrg.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <TextArea
            v-if="formControls.positionDirector.active"
            name="positionDirector"
            label="Должность"
            :valid="formControls.positionDirector.isValid"
            :errors="formControls.positionDirector.errors"
            :touched="formControls.positionDirector.isTouched"
            :shouldValidate="!!formControls.positionDirector.rules"
            :value="formControls.positionDirector.value"
            :disabled="formControls.positionDirector.disabled"
            :invalidityInfo="formControls.positionDirector.invalidityInfo"
            @change="inputHandler"
            @blur="inputHandler"
          />
        </div>
      </FormBox>

      <!-- Блок 'Плательщик' -->
      <FormBox v-if="getVisibleBodyForm" title="Плательщик">
        <Select
          label="Плательщиком является"
          name="payerSubjectType"
          :disabled="formControls.payerSubjectType.disabled"
          :valid="formControls.payerSubjectType.isValid"
          :errors="formControls.payerSubjectType.errors"
          :touched="formControls.payerSubjectType.isTouched"
          :shouldValidate="!!formControls.payerSubjectType.rules"
          :options="Object.values(PAYER_TYPES)"
          :value="formControls.payerSubjectType.value"
          :invalidityInfo="formControls.payerSubjectType.invalidityInfo"
          @change="(evt) => {
            ////// Очищаем поля ИНН и КПП при переключении 'Плательщиком является' из ИП в ЮЛ и из ЮЛ в ИП
            if (
              (formControls.payerSubjectType.value === PAYER_TYPES.ip.value && evt.target.value === PAYER_TYPES.ur.value) ||
              (formControls.payerSubjectType.value === PAYER_TYPES.ur.value && evt.target.value === PAYER_TYPES.ip.value)
            ) {
              setFormControlProps({name: 'payerInn', props: {...DEFAULT_FORM_CONTROLS['payerInn'], isBlur: true}});
              setFormControlProps({name: 'payerKpp', props: {...DEFAULT_FORM_CONTROLS['payerKpp'], isBlur: true}});
              // setFormControlProps({name: 'payerShortName', props: {...DEFAULT_FORM_CONTROLS['payerShortName'], isBlur: true}});
            }
            ////// Очищаем поле КПП при переключении 'Плательщиком является' из ФЛ в ЮЛ и из ФЛ в ИП
            if (
              (formControls.payerSubjectType.value === PAYER_TYPES.fl.value && evt.target.value === PAYER_TYPES.ur.value) ||
              (formControls.payerSubjectType.value === PAYER_TYPES.fl.value && evt.target.value === PAYER_TYPES.ip.value)
            ) {
              setFormControlProps({name: 'payerKpp', props: {...DEFAULT_FORM_CONTROLS['payerKpp'], isBlur: true}});
              // setFormControlProps({name: 'payerShortName', props: {...DEFAULT_FORM_CONTROLS['payerShortName'], isBlur: true}});
            }
            ////// Очищаем поле ИНН при переключении 'Плательщиком является' из ФЛ в ЮЛ и из ЮЛ в ФЛ
            if (
              (formControls.payerSubjectType.value === PAYER_TYPES.fl.value && evt.target.value === PAYER_TYPES.ur.value) ||
              (formControls.payerSubjectType.value === PAYER_TYPES.ur.value && evt.target.value === PAYER_TYPES.fl.value)
            ) {
              setFormControlProps({name: 'payerInn', props: {...DEFAULT_FORM_CONTROLS['payerInn'], isBlur: true}});
            }
            inputHandler(evt);
          }"
          @blur="inputHandler"
        />
        <TextArea
          v-if="formControls.payerName.active"
          name="payerName"
          :label="
            formControls.payerSubjectType.value === PAYER_TYPES.fl.value
              ? 'Фамилия Имя Отчество'
              : 'Полное наименование организации'
          "
          :valid="formControls.payerName.isValid"
          :errors="formControls.payerName.errors"
          :touched="formControls.payerName.isTouched"
          :shouldValidate="!!formControls.payerName.rules"
          :value="formControls.payerName.value"
          :disabled="formControls.payerName.disabled"
          :invalidityInfo="formControls.payerName.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <!-- <Input
          v-if="formControls.payerShortName.active"
          name="payerShortName"
          stype="min"
          label="Сокращенное наименование"
          :valid="formControls.payerShortName.isValid"
          :errors="formControls.payerShortName.errors"
          :touched="formControls.payerShortName.isTouched"
          :shouldValidate="!!formControls.payerShortName.rules"
          :value="formControls.payerShortName.value"
          :disabled="formControls.payerShortName.disabled"
          :invalidityInfo="formControls.payerShortName.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        /> -->
        <Input
          v-if="formControls.payerInn.active"
          name="payerInn"
          stype="min"
          :label="`${formControls.payerSubjectType.value === 'ur' ? 'ИНН организации' : 'ИНН'}`"
          :mask="`${formControls.payerSubjectType.value === 'ur' ? '9999999999' : '999999999999'}`"
          :valid="formControls.payerInn.isValid"
          :errors="formControls.payerInn.errors"
          :touched="formControls.payerInn.isTouched"
          :shouldValidate="!!formControls.payerInn.rules"
          :value="formControls.payerInn.value"
          :disabled="formControls.payerInn.disabled"
          :invalidityInfo="formControls.payerInn.invalidityInfo"
          :inputControls="!isEdit ? {} : {
            buttonType: 'normal',
            buttonTitle: 'Заполнить',
            buttonHandler: () => getPayerFieldsFromBasis({inn: formControls.payerInn.value}),
            buttonStyles: {width: '130px', padding: '0'},
            buttonDisabled: !formControls.payerInn.isValid,
          }"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Input
          v-if="formControls.payerKpp.active"
          name="payerKpp"
          stype="min"
          label="КПП"
          mask="${9}"
          :valid="formControls.payerKpp.isValid"
          :errors="formControls.payerKpp.errors"
          :touched="formControls.payerKpp.isTouched"
          :shouldValidate="!!formControls.payerKpp.rules"
          :value="formControls.payerKpp.value"
          :disabled="formControls.payerKpp.disabled"
          :invalidityInfo="formControls.payerKpp.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Input
          v-if="formControls.payerPhone.active"
          name="payerPhone"
          stype="min"
          :label="
            formControls.payerSubjectType.value === PAYER_TYPES.fl.value
              ? 'Телефон'
              : 'Телефон организации'
          "
          mask="+7 (999) 999 99-99"
          :valid="formControls.payerPhone.isValid"
          :errors="formControls.payerPhone.errors"
          :touched="formControls.payerPhone.isTouched"
          :shouldValidate="!!formControls.payerPhone.rules"
          :value="formControls.payerPhone.value"
          :disabled="formControls.payerPhone.disabled"
          :invalidityInfo="formControls.payerPhone.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Select
          v-if="formControls.payerCountryId.active"
          label="Страна"
          name="payerCountryId"
          :valid="formControls.payerCountryId.isValid"
          :errors="formControls.payerCountryId.errors"
          :touched="formControls.payerCountryId.isTouched"
          :shouldValidate="!!formControls.payerCountryId.rules"
          :disabled="formControls.payerCountryId.disabled"
          :options="optionsCountry"
          :value="formControls.payerCountryId.value"
          :invalidityInfo="formControls.payerCountryId.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Select
          v-if="formControls.payerRegionId.active"
          label="Регион"
          name="payerRegionId"
          :disabled="formControls.payerRegionId.disabled"
          :valid="formControls.payerRegionId.isValid"
          :errors="formControls.payerRegionId.errors"
          :touched="formControls.payerRegionId.isTouched"
          :shouldValidate="!!formControls.payerRegionId.rules"
          :options="optionsRegion"
          :value="formControls.payerRegionId.value"
          :invalidityInfo="formControls.payerRegionId.invalidityInfo"
          :hasSearch="true"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Input
          v-if="formControls.payerCity.active"
          name="payerCity"
          label="Населенный пункт"
          :valid="formControls.payerCity.isValid"
          :errors="formControls.payerCity.errors"
          :touched="formControls.payerCity.isTouched"
          :shouldValidate="!!formControls.payerCity.rules"
          :value="formControls.payerCity.value"
          :disabled="formControls.payerCity.disabled"
          :invalidityInfo="formControls.payerCity.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Input
          v-if="formControls.payerAddress.active"
          name="payerAddress"
          :label="
            formControls.payerSubjectType.value === PAYER_TYPES.fl.value
              ? 'Адрес начиная с улицы'
              : `Адрес (согласно выписке ${formControls.payerSubjectType.value === 'ur' ? 'ЕГРЮЛ' : 'ЕГРИП'}) начиная с улицы`
          "
          :valid="formControls.payerAddress.isValid"
          :errors="formControls.payerAddress.errors"
          :touched="formControls.payerAddress.isTouched"
          :shouldValidate="!!formControls.payerAddress.rules"
          :value="formControls.payerAddress.value"
          :disabled="formControls.payerAddress.disabled"
          :invalidityInfo="formControls.payerAddress.invalidityInfo"
          @change="inputHandler"
          @blur="inputHandler"
        />
        <Input
          v-if="formControls.payerPostalCode.active"
          name="payerPostalCode"
          stype="min"
          label="Индекс"
          mask="999999"
          :valid="formControls.payerPostalCode.isValid"
          :errors="formControls.payerPostalCode.errors"
          :touched="formControls.payerPostalCode.isTouched"
          :shouldValidate="!!formControls.payerPostalCode.rules"
          :value="formControls.payerPostalCode.value"
          :disabled="formControls.payerPostalCode.disabled"
          :invalidityInfo="formControls.payerPostalCode.invalidityInfo"
          @change="inputHandler"
           @blur="inputHandler"
        />
      </FormBox>

      <FormBox title="Дополнительно">
        <div class="form-box-group">
          <Input
            name="fnsCode"
            label="Код ИФНС"
            stype="min"
            mask="9999"
            :valid="formControls.fnsCode.isValid"
            :errors="formControls.fnsCode.errors"
            :touched="formControls.fnsCode.isTouched"
            :shouldValidate="!!formControls.fnsCode.rules"
            :value="formControls.fnsCode.value"
            :disabled="formControls.fnsCode.disabled"
            :invalidityInfo="formControls.fnsCode.invalidityInfo"
            :inputControls="{
              info: 'Код ИФНС можно уточнить по ссылке https://service.nalog.ru/addrno.do'
            }"
            @change="inputHandler"
            @blur="inputHandler"
          />
          <Input
            v-if="formControls.partnerOrganizationName.value!=null"
            name="partnerOrganizationName"
            label="Партнёрская организация"
            :valid="true"
            :touched="true"
            :shouldValidate="false"
            :disabled="true"
            :value="formControls.partnerOrganizationName.value"
            :invalidityInfo="formControls.partnerOrganizationName.invalidityInfo"
          />
        </div>
      </FormBox>

      <TextArea v-if="formControls.clientComment.value!=null"
        name="clientComment"
        label="Комментарий клиента"
        :valid="true"
        :touched="true"
        :shouldValidate="false"
        :disabled="true"
        :value="formControls.clientComment.value"
        :invalidityInfo="formControls.clientComment.invalidityInfo"
      />
      <Comments type="preapp" :orderId="getPreApplicationInfo.orderId" />
      <br />
      <SMSSection
        v-if="getDisplaySmsSection"
        :history="smsHistory"
        :smsTypeOptions="smsTypeOptions"
        :filialAddress="smsInfo.filialAddress"
        :filialPhone="smsInfo.filialPhone"
        :filialName="smsInfo.filialName"
        :isNotAllowed="!isAllowedToSend"
        @sendSms="({smsType, text}) => sendPreappSms({id: $route.params.orderId, smsType, text})"
        @getSmsList="getPreappSmsList($route.params.orderId)"
        @clearSmsList="$store.commit('smsState/CLEAR_SMS_STATE')"
      />

      <div
        v-if="getVisibleBodyForm && isEdit !== false"
        class="form-control-buttons"
      >
        <Button @click="$router.push({ name: 'preapp-home' })" color="purple-ghost" type="button"
          >Отмена</Button
        >
        <div class="next-tab-button-wrapper"
          @mouseenter="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          <Button
            :disabled="!getIsFormValid"
            @click.prevent="handleClickSave"
            color="purple"
            type="button"
          >Сохранить</Button>
          <div
            v-if="!getIsFormValid && showTooltip"
            class="tooltip" 
            @mouseenter="showTooltip = true"
            @mouseleave="showTooltip = false"
          >
            <span>Некоторые поля заполнены некорректно.&nbsp;</span>
            <span @click.prevent="checkValidityAndScroll">Показать</span>
          </div>
        </div>
      </div>    
    </Form>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

import Form from "@/components/UI/form/Form.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Input from "@/components/UI/form/Input.vue";
import Select from "@/components/UI/form/Select.vue";
import TextArea from "@/components/UI/form/TextArea.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import InputRadio from "@/components/UI/form/InputRadio.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Comp from "@/components/UI/icon/Comp.vue";
import Usb from "@/components/UI/icon/Usb.vue";
import Archive from "@/components/UI/icon/Archive.vue";
import FormItem from "@/components/UI/form/FormItem.vue";
import Button from "@/components/UI/Button.vue";
import Comments from "@/components/Comments.vue";
import FnsExistingCertList from "@/components/FnsExistingCertList.vue";
import ComboWrapper from "@/components/UI/form/ComboWrapper.vue";
import SMSSection from "@/components/SMSSection.vue";
import {
  ROAD_TYPES,
  APARTMENT_TYPES,
  BUILDING_TYPES,
  LOCALITY_TYPES,
} from '@/constants/address';

import { DEFAULT_FORM_CONTROLS } from "@/store/modules/preapplication/preapplication.defaultFormControls";
import {
  PAYER_TYPES,
} from "@/store/modules/preapplication/preapplication.constants";
import { SMS_TYPE_OPTIONS } from "@/store/modules/sms/sms.config";

export default Vue.extend ({
  name: "PreAppProposalForm",

  components: {
    ComboWrapper,
    Form,
    FormBox,
    Input,
    TextArea,
    InputRadio,
    Select,
    FormItem,
    Checkbox,
    Button,
    IconBase,
    Comp,
    Usb,
    Archive,
    Comments,
    FnsExistingCertList,
    SMSSection,
  },

  data() {
    return {
      commentText: "",
      additionalStatusId: null,
      PAYER_TYPES,
      DEFAULT_FORM_CONTROLS,
      showTooltip: false,
      LOCALITY_TYPES,
      ROAD_TYPES,
      BUILDING_TYPES,
      APARTMENT_TYPES,
    };
  },

  computed: {
    ...mapState("preApplicationState", [
      "formControls",
      "isFormValid",
      "optionsCountry",
      "optionsRegion",
      "optionsAdditionalStatuses",
      "optionsPassportDivisions",
      "isEdit",
      "creationDate",
      "applicationId",
      "preappPaymentStatus",
      "statusId",
    ]),
    ...mapState("smsState", ['smsInfo', 'smsHistory', 'isAllowedToSend']),
    ...mapGetters("preApplicationState", [
      "getVisibleBodyForm",
      "getPreApplicationInfo",
      "getAdditionalStatusVisible",
      "isIdentification",
      "getFnsExistingCerts",
      "getIsFormValid",
      "getDisplaySmsSection",
    ]),
    ...mapGetters("productsState", [
      "getHasTariffInsurance",
    ]),

    getAddressTitle() {
      if (this.formControls.typeEntre.value === "ur") {
        return "Юридический адрес";
      }
      return "Фактический адрес";
    },

    addressReadOnly() {
      return [
        this.formControls.cityReal.value || this.formControls.cityLaw.value,
        this.formControls.addressReal.value || this.formControls.addressLaw.value,
      ]
      .filter(Boolean)
      .join(', ');
    },

    smsTypeOptions() {
      return Object.entries(SMS_TYPE_OPTIONS)
        .map(([value, label]) => ({value, label}));
    }
  },

  methods: {
    ...mapActions("preApplicationState", [
      "getReferenceCountries",
      "getReferenceRegions",
      "inputHandler",
      "typeSelectionHandler",
      "nextActiveTab",
      "checkValidityAndScroll",
      "commitNewStatus",
      "submitHandler",
      "setFormControlProps",
      "getPayerFieldsFromBasis",
      "getDivisionNameByCode",
      "switchDivisionSelectToTextArea",
    ]),
    ...mapActions("comments", ['addComment']),
    ...mapActions("smsState", ['getPreappSmsList', 'sendPreappSms']),

    nextTab() {
      // Валидация отключена в рамках задачи 27
      // this.checkValidityAndScroll();

      // if (this.isFormValid) {
      //   this.nextActiveTab(true);
      // }

      this.nextActiveTab(true);
    },

    handleClickSave() {
      this.submitHandler();
    },

    async handleClickSaveStatus() {
      const { success } = await this.commitNewStatus(this.additionalStatusId);
      if (success) {
        this.$store.commit('preApplicationState/SET_CHANGE_STATUS_VISIBILITY', false);
        this.addComment({
        type: "preapp",
        orderId: this.getPreApplicationInfo.orderId,
        commentText: this.commentText,
        });
        this.commentText = "";
      }
    },

    handleCancelSaveStatus() {
      this.$store.commit('preApplicationState/SET_CHANGE_STATUS_VISIBILITY', false);
      this.commentText = '';
      this.additionalStatusId =
        this.optionsAdditionalStatuses
          .some(({value}) => value === this.statusId) ? this.statusId : null;
    },

    onChangeComment(event) {
      this.commentText = event.target.value;
    }
  },

  mounted() {
    this.getReferenceRegions();
  },

  watch: {
    statusId() {
      this.additionalStatusId =
        this.optionsAdditionalStatuses
          .some(({value}) => value === this.statusId) ? this.statusId : null;
    },
  },
})
</script>
<style lang="scss">
.ie .form-header-top .title {
  margin-right: auto;
}

.type-entre {
  margin-bottom: 16px;
  &[touched] {
    border: 1px solid #ff7b7b;
    &[valid]{
      border: none;
    }
  }
  .form-item-radio {
    width: 100%;
    justify-content: space-between;
  }
}

.proposal-form {
  width: 900px;
  margin: 0 auto;

  .button_file {
    position: relative;

    label {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  .product-list {
    margin-top: 35px;
  }

  .result-price {
    margin-top: 45px;
    padding: 20px 0 10px;
    border-top: 1px dashed #e0dfee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-weight: normal;
    font-size: 21px;
    line-height: 26px;
    &__title {
      text-align: left;
    }
    &__current {
      text-align: right;
    }
  }
}

.form-header {
  margin-bottom: 25px;
  &-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 5px;
      border-bottom: 1px dashed #cccccc;
    }
    & > div {
      position: relative;
      background: #fff;
      z-index: 1;
    }
    .title {
      position: relative;
      font-size: 25px;
      color: #000;
    }

    .icons {
      position: absolute;
      bottom: 4px;
      left: -10px;
      transform: translate(-100%, 0);
      display: flex;
      align-items: center;

      svg {
        & + svg {
          margin-left: 10px;
        }
        path {
          fill: #6f61e9;
        }
      }
    }

    .status {
      font-size: 16px;
      text-align: right;
      color: #333;
      &__app-id{
        cursor: pointer;
        &:hover{
          color: #6f61e9;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    ul {
      font-size: 14px;
      line-height: 24px;
      color: #808080;
      list-style-type: none;
      padding: 0;
      margin: 0;
      span {
        font-weight: 500;
      }
    }
  }
}

.next-tab-button-wrapper {
  position: relative;
  .tooltip {
    position: absolute;
    top: 50%;
    right: -5px;
    z-index: 1;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    width: 220px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
    span:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
